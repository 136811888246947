<template>
    <div class="empty-state">
        <img
            alt="Not found"
            class="empty-state__img"
            :class="`empty-state__img--${imgSize}`"
            :src="require(`@/assets/img/empty-states/${img}`)"
        />
        <h5 v-if="title" class="empty-state__title" :class="{ 'mb-0': !text }">{{ title }}</h5>
        <p v-if="text" class="empty-state__text">{{ text }}</p>
        <div v-if="showButton">
            <ui-button variant="outline-primary" class="mt-1" @click="$emit('redirect', this.url)">
                Review
            </ui-button>
        </div>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';
export default {
    name: 'EmptyState',
    components: { UiButton },
    props: {
        img: {
            type: String,
            default: 'empty-box.svg',
            required: false,
        },
        imgSize: {
            type: String,
            default: 'sm',
            required: false,
        },
        text: {
            type: String,
            default: null,
            required: false,
        },
        title: {
            type: String,
            default: null,
            required: false,
        },      
        showButton: {
            type: Boolean,
            default: false,
            required: false,
        },  
        url: {
            type: String,
            default: null,
            required: false,
        },
    },
};
</script>

<style lang="scss">
.empty-state {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__img {
        height: auto;
        max-width: 100%;
        width: 100%;
        margin-bottom: 20px;

        &--sm {
            max-width: 150px;
        }

        &--md {
            max-width: 250px;
        }

        &--lg {
            max-width: 350px;
        }
    }

    &__title {
        color: $general-black;
        font-size: 1.12rem;
    }

    &__text {
        color: $ecart-secondary-500;
        font-size: 1.05rem;
    }
}
</style>