import { get } from 'lodash';
import { filters as importedFilters } from '@/data';
export default {
    data() {
        return {
            _available_filters: null,
            oldFilters: null,

        }
    },
    watch: {
        filters: {
            immediate: true,
            deep: true,
            handler(filters) {
                this._replaceParams(filters);
            },
        },
    },

    mounted() {
        this.filters = this._filtersByQuery(this.filters);
    }, 

    methods: {
        async _getFilters(route = '') {
            try {
                if(route === 'transfers') {
                    this._available_filters = importedFilters.transfers;
                }else{
                    const { data } = await this.$axios.get(`/_/${route}/filters`);
                    this._available_filters = data;
                }
            } catch (error) {
                this.showError(error);
            }
        },

        _changeFilters(callback) {
            const oldFiltersCopy = JSON.parse(JSON.stringify(this.oldFilters));
            this.oldFilters = JSON.parse(JSON.stringify(this.filters));
            if (this.filters.page === get(oldFiltersCopy, 'page')) {
                this._replaceParams({ ...this.filters });
                if (callback) callback();
                this.filters.page = 1;
                return;
            }
            this._replaceParams({ ...this.filters });
            if (callback) callback();
        },

        _clearFilters(params) {
            for (const key in params) {
                if (Object.hasOwnProperty.call(params, key)) {
                    const prop = key;
                    const element = params[key];
                    if (element === 'all' || element === '') {
                        delete params[prop];
                    }
                }
            }
        },

        _replaceParams(queryParams) {
            const searchParams = new URLSearchParams(window.location.search);
            const copyQueryParams = { ...queryParams };
            for (const key of Object.keys(copyQueryParams)) {
                let value = copyQueryParams[key];
                value = Array.isArray(value) ? value.join(',') : value;
                delete copyQueryParams.range;
                if (value) {
                    searchParams.set(key, value);
                } else {
                    searchParams.delete(key);
                }
            }
            window.history.replaceState({}, '', `?${searchParams.toString()}`);
        },

        _filtersByQuery(filters) {
            const newFilters = { ...filters };
            for (const key of Object.keys(newFilters)) {
                const newValue = this.$route.query[key];
                if (newValue) {
                    if (!isNaN(newValue)) {
                        newFilters[key] = parseFloat(newValue);
                    } else {
                        newFilters[key] = newValue;
                    }
                }
            }
            return newFilters;
        },


    },
};
