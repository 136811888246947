<template>
    <div class="ui-table" :class="tableClasses">
        <div class="table-responsive">
            <table class="ui-table__table table mb-0" :class="[stackedClass, alignClass]" :aria-describedby="describedBy">
                <thead class="ui-table__head">
                    <tr>
                        <template v-for="field in fields" :key="field.key">
                            <th
                                v-if="field.label !== 'Actions' || ( field.label == 'Actions' && CAN(scopes))"
                                :class="[{ 'ui-table__head-th--sortable': field.sortable }, field.thClass]"
                                @click="sort(field)"
                            >
                            <div v-if="field.sortable" class="ui-table__head-th-container">
                                <slot :name="`column(${field.key})`">{{ field.label }}</slot>
                                <div v-if="field.sortable" class="ui-table__sort-icon-container">
                                    <span class="ui-table__sort-icon fas fa-sort-up" :class="{ 'ui-table__sort-icon--active': sortBy === field.key && sortType === 'asc' }"></span>
                                    <span class="ui-table__sort-icon fas fa-sort-down" :class="{ 'ui-table__sort-icon--active': sortBy === field.key && sortType === 'desc' }"></span>
                                </div>
                            </div>
                            <slot v-else :name="`column(${field.key})`">{{ field.label }}</slot>
                            </th>
                        </template>
                    </tr>
                </thead>
                <tbody v-if="busy" class="ui-table__body">
                    <tr class="ui-table__body-tr">
                        <td class="ui-table__loading-cell" :colspan="fields ? fields.length : 1">
                            <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="!items || !items.length" class="ui-table__body">
                    <tr class="ui-table__body-tr">
                        <td class="ui-table__body-td ui-table__body-td--empty loading-cell" :colspan="fields ? fields.length : 1">
                            <empty-state :title="noRecordsMessage"/>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else class="ui-table__body">
                    <template v-for="(item, index) in items" :key="index">
                        <tr class="ui-table__body-tr" :class="{ 'ui-table__body-tr--odd': index % 2 !== 0 }">
                            <template v-for="field in fields" :key="field.key">
                                <td
                                    v-if="field.key !== 'actions' || (field.key == 'actions' && CAN(scopes))"
                                    :data-label="field.label"
                                    class="ui-table__body-td border-0"
                                    :class="field.tdClass"
                                >
                                <div class="ui-table__cell">
                                    <slot
                                        :name="`cell(${field.key})`"
                                        :item="item"
                                        :toggle-details="() => toggleDetails(index)"
                                        :details-showing="!!showDetails[index]"
                                    >
                                    {{ item[field.key] }}
                                </slot>
                                </div>
                                </td>
                            </template>
                        </tr>
                        <template v-if="showDetails[index]">
                            <tr aria-hidden="true" role="presentation" class="d-none"></tr>
                            <tr class="ui-table__body-tr" :class="{ 'ui-table__body-tr--odd': index % 2 !== 0 }">
                                <td class="ui-table__body-td border-0" :colspan="fields ? fields.length : 1">
                                    <div class="ui-table__details">
                                        <slot name="row-details" :item="item"></slot>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <slot name="cellFull" :item="item"></slot>
                    </template>
                </tbody>
            </table>
        </div> 
    </div>
</template>

<script>
import EmptyState from '@/components/ui/EmptyState.vue';

export default {
    name: 'UiTable',
    components: { EmptyState },
    props: {
        align: {
            type: String,
            default: 'middle',
            required: false,
        },
        bordered: {
            type: Boolean,
            default: false,
            required: false,
        },
        busy: {
            type: Boolean,
            default: false,
            required: false,
        },
        describedBy: {
            type: String,
            default: null,
            required: false,
        },
        fields: {
            type: Array,
            default: null,
            required: false,
        },
        items: {
            type: Array,
            default: null,
            required: false,
        },
        noRecordsMessage: {
            type: String,
            default: 'No records found...',
            required: false,
        },
        shadow: {
            type: Boolean,
            default: true,
            required: false,
        },
        sortBy: {
            type: String,
            default: null,
            required: false,
        },
        sortType: {
            type: String,
            default: null,
            required: false,
        },
        stacked: {
            type: String,
            default: 'lg',
            required: false,
        },
        scopes: {
            type: Array,
            default: null,
            required: false,
        }
    },
    data() {
        return {
            stackedClass: this.stacked ? `table-stacked-${this.stacked}` : '',
            alignClass: `align-${this.align}`,
            showDetails: {},
        };
    },
    computed: {
        tableClasses() {
            const classes = [];

            if (this.bordered) {
                classes.push('ui-table--bordered');
            }

            if (this.shadow) {
                classes.push('ui-list-group--shadow');
            }

            return classes;
        },
    },
    methods: {
        sort(column) {
            if (!column.sortable) {
                return;
            }

            let type = null;
            let by = null;

            if (!this.sortType || this.sortBy !== column.key) {
                type = 'asc';
                by = column.key;
            }

            if (this.sortType === 'asc' && this.sortBy === column.key) {
                type = 'desc';
                by = column.key;
            }

            this.$emit('update:sortType', type)
            this.$emit('update:sortBy', by);
        },
        toggleDetails(index) {
            if (this.showDetails[index]) {
                delete this.showDetails[index];
                return;
            }

            this.showDetails[index] = true;
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-table {
    background-color: $general-white;
    border-radius: 8px;

    &--shadow {
        box-shadow: 0px 8px 16px rgba(#000000, 0.15);
    }

    &--bordered {
        border: 1px solid #dae0ec;
    }

    &__table {
        > :not(:first-child) {
            border-top: none;
        }
    }

    &__head-th {
        border-bottom: 2px solid rgba(#212529, 0.05);
        color: #333333;
        font-weight: 700;
        padding: 10px;
        vertical-align: middle;
        
        &--sortable {
            cursor: pointer;
        }
    }

    &__head-th-container {
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &__body-tr {
        padding: 10px;

        &--odd {
            background-color: rgba(#000000, 0.02);
        }
    }

    &__body-td {
        border-bottom: 2px solid rgba(#212529, 0.05);
        color: #333333;
        padding: 20px 10px;

        &--empty {
            text-align: center;
            background-color: #ffffff;
        }
    }

    &__loading-cell {
        padding: 50px 15px;
        text-align: center;
    }

    &__sort-icon-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
    }

    &__sort-icon {
        line-height: 1;
        color: $ecart-secondary-300;
        font-size: 1.04rem;

        &:first-child {
            margin-bottom: -7px;
        }

        &:last-child {
            margin-top: -7px;
        }

        &--active {
            color: $ecart-secondary-500;
        }
    }
}
</style>