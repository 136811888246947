<template>
    <nav v-if="pages > 1" class="ui-pagination" aria-label="navigation">
        <ul class="pagination">
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    :class="modelValue === 1 && 'ui-pagination__link--disabled'"
                    aria-label="Previous"
                    @click="changePage(1)"
                >
                    <span class="fas fa-angle-double-left"></span>
                </button>
            </li>
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    :class="modelValue === 1 && 'ui-pagination__link--disabled'"
                    aria-label="Previous"
                    @click="changePage(modelValue - 1)"
                >
                    <span class="fas fa-chevron-left"></span>
                </button>
            </li>
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    :class="modelValue === 1 && 'ui-pagination__link--disabled'"
                    aria-label="Previous"
                    @click="changePage(modelValue - 1)"
                >
                    Previous
                </button>
            </li>
            <li
                v-show="modelValue - 1 > 0"
                v-for="i in 1"
                :key="i" class="ui-pagination__item page-item"
                @click="changePage(modelValue - i)"
            >
                <button class="ui-pagination__link page-link" :aria-posinset="modelValue">
                    {{ modelValue - i }}
                </button>
            </li>
            <li class="ui-pagination__item page-item">
                <span class="ui-pagination__link ui-pagination__link--active page-link" :aria-posinset="modelValue">
                    {{ modelValue }}
                </span>
            </li>
            <template v-if="modelValue < pages">
              <li v-for="i in 1" :key="i" class="ui-pagination__item page-item" @click="changePage(i + modelValue)">
                  <button class="ui-pagination__link page-link" :aria-posinset="modelValue">
                      {{ i + modelValue }}
                  </button>
              </li>
            </template>
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    aria-label="Next"
                    @click="changePage(modelValue + 1)"
                    :class="modelValue === pages && 'ui-pagination__link--disabled'"
                >
                    Next
                </button>
            </li>
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    aria-label="Next"
                    @click="changePage(modelValue + 1)"
                    :class="modelValue === pages && 'ui-pagination__link--disabled'"
                >
                    <span class="fas fa-chevron-right"></span>
                </button>
            </li>
            <li class="ui-pagination__item page-item">
                <button
                    class="ui-pagination__link page-link"
                    aria-label="Next"
                    @click="changePage(pages)"
                    :class="modelValue === pages && 'ui-pagination__link--disabled'"
                >
                    <span class="fas fa-angle-double-right"></span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'UiPagination',
    props: {
        modelValue: {
            type: Number,
            default: 1,
        },
        pages: {
            type: Number,
            default: 1,
        },
    },
    methods: {
        changePage(page) {
            this.$emit('update:modelValue', page);
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-pagination {
    &__link {
        background-color: transparent;
        color: $general-black;
        font-weight: 400;
        border: none;
        padding: 8px 10px;
        line-height: 110%;

        &:focus {
            box-shadow: none;
            color: $general-black;
        }

        &:hover {
            background-color: $ecart-accent-100;
        }

        &--disabled {
            pointer-events: none;
            color: $ecart-secondary-400;

            &:focus {
                color: $ecart-secondary-400;
            }
        }

        &--active {
            background-color: $ecart-primary;
            color: #ffffff;

            &:hover {
                background-color: $ecart-primary;
            }
        }
    }
}
</style>
